import $ from "jquery";

localStorage.setItem("mf_record_user", "1");

/** The Mouseflow event queue. */
window._mfq = window._mfq || [];

/**
 * Add a Mouseflow event to the queue.
 *
 * @param  eventName
 * @param value
 */
export function addMouseflowEvent(eventName, value) {
    window._mfq.push([eventName, value]);
}

/** Record the current page. */
addMouseflowEvent("tag", $("body").attr("id"));

$("button").click(function () {
    addMouseflowEvent("tag", (this.id || "button") + "-click");
});

window.addMouseflowEvent = addMouseflowEvent;

window.mouseflow && typeof window.mouseflow.start === "function" && window.mouseflow.start();
